<template>
    <CSidebar
        fixed
        :minimize="minimize"
        :show="show"
        @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
    >
        <CSidebarBrand class="d-md-down-none" to="/">
            <img src="../../assets/img/topaqol_logo.jpg" class="c-sidebar-brand-full c-icon c-icon-custom-size" height="35"/>
        </CSidebarBrand>

        <CRenderFunction flat :content-to-render="$options.nav"/>
        <CSidebarMinimizer
            class="d-md-down-none"
            @click.native="$store.commit('set', ['sidebarMinimize', !minimize])"
        />
    </CSidebar>
</template>

<script>
  import nav from './_nav'

  export default {
    name: 'TheSidebar',
    nav,
    computed: {
      show () {
        return this.$store.state.store.sidebarShow
      },
      minimize () {
        return this.$store.state.store.sidebarMinimize
      }
    }
  }
</script>

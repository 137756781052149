export default [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavTitle',
        _children: ['Converter Admin']
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Users',
        to: '/user/list',
        icon: 'cil-chart-pie'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Required Channels',
        to: '/competitions/list',
        icon: 'cil-chart-pie'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'All Searched Audios',
        to: '/history/list',
        icon: 'cil-chart-pie'
      }
    ]
  }
]